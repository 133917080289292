// src/components/Home.js

import React from 'react';
import logo from '../img/lmcloud-logoblk.png'; // Adjust the path as necessary

const Home = () => (
  <div className="container-fluid">
    <main className="row hero-section">
      <div className="col-12 col-md-8 hero-content">
        <h1><b>BOOSTING YOUR</b></h1>
        <h1><b>CLOUD POTENTIAL</b></h1>
        <br />
        <h3>A new perspective in cloud consulting.</h3>
        <br />
        <a href="/contact" className="btn btn-primary">ENQUIRE NOW</a>
      </div>
      <div className="col-12 col-md-4 hero-logo">
        <img src={logo} alt="LM Cloud Consulting Logo" className="img-fluid" />
      </div>
    </main>
  </div>
);

export default Home;
