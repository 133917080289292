// src/components/Navbar.js

import React from 'react';
import { NavLink } from 'react-router-dom';

const Navbar = () => (
  <div>
    
    {/* Desktop Navigation */}
    <nav className="navbar navbar-expand-lg navbar-dark desktop-nav">
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <NavLink className="nav-item nav-link" to="/" activeClassName="active-link">LM Cloud</NavLink>
          <NavLink to="/services" className="nav-link" activeClassName="active-link">Services</NavLink>
          <NavLink to="/contact" className="nav-link" activeClassName="active-link">Contact</NavLink>
          <NavLink to="/booking" className="nav-link" activeClassName="active-link">Book</NavLink>
          <NavLink to="/about" className="nav-link" activeClassName="active-link">About</NavLink>
        </div>
      </div>
    </nav>
  </div>
);

export default Navbar;
