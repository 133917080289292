// src/components/About.js

import React from 'react';
import logo from '../img/lmcloud-logoblk.png';

const About = () => {
  return (
    <div className="container-fluid">
      <div className="container">
        <h1 className="display-3 fw-bold text-center mt-5 mb-3">About Us</h1>
        <p className="text-secondary mb-5 text-center">
          At LM Cloud Consulting, we are dedicated to transforming your business through innovative cloud solutions. Our team of experts brings extensive experience in cloud computing, digital transformation, and business intelligence to help you achieve your organizational goals.
        </p>
        <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
      </div>

      <div className="container overflow-hidden">
        <div className="row gy-5 gx-md-5 justify-content-center">
          <div className="col-10 col-md-5 col-xl-4 overflow-hidden">
            <div className="row gy-4">
              <div className="col-12 col-lg-10">
                <h4 className="mb-3">Innovative Solutions</h4>
                <p className="mb-3 text-secondary">
                  We offer cutting-edge solutions tailored to your business needs, ensuring scalability and efficiency.
                </p>
              </div>
            </div>
          </div>
          <div className="col-10 col-md-5 col-xl-4 overflow-hidden">
            <div className="row gy-4">
              <div className="col-12 col-lg-10">
                <h4 className="mb-3">Expert Team</h4>
                <p className="mb-3 text-secondary">
                  Our experienced team is committed to delivering high-quality cloud consulting services.
                </p>
              </div>
            </div>
          </div>
          <div className="col-10 col-md-5 col-xl-4 overflow-hidden">
            <div className="row gy-4">
              <div className="col-12 col-lg-10">
                <h4 className="mb-3">Award-Winning Service</h4>
                <p className="mb-3 text-secondary">
                  Recognized for excellence in cloud solutions, we strive to exceed client expectations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="py-3 py-md-5 py-xl-8">
        <div className="container">
          <div className="row gy-5 gy-lg-0 align-items-lg-center">
            <div className="col-12 col-lg-6">
              <img className="img-fluid rounded" loading="lazy" src={logo} alt="About Us" />
            </div>
            <div className="col-12 col-lg-6">
              <div className="row justify-content-xl-end">
                <div className="col-12 col-xl-11">
                  <h2 className="h1 mb-3">Our Mission</h2>
                  <p className="lead fs-4 text-secondary mb-5">
                    To empower businesses by harnessing the full potential of the cloud, driving innovation and efficiency through personalized solutions.
                  </p>
                  <div className="d-flex mb-4">
                    <div>
                      <span className="btn btn-primary bsb-btn-circle custom-circle-btn me-4">1</span>
                    </div>
                    <div>
                      <h4 className="mb-3">Consulting</h4>
                      <p className="mb-0 text-secondary">
                        Providing expert guidance to help you navigate your cloud journey.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex mb-4">
                    <div>
                      <span className="btn btn-primary bsb-btn-circle custom-circle-btn me-4">2</span>
                    </div>
                    <div>
                      <h4 className="mb-3">Implementation</h4>
                      <p className="mb-0 text-secondary">
                        Seamlessly integrating cloud solutions tailored to your business needs.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div>
                      <span className="btn btn-primary bsb-btn-circle custom-circle-btn me-4">3</span>
                    </div>
                    <div>
                      <h4 className="mb-3">Support</h4>
                      <p className="mb-0 text-secondary">
                        Offering ongoing support to ensure your success in the cloud.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default About;
