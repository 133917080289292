// src/components/Footer.js

import React from 'react';
import logo from '../img/lmcloud-logoblk.png';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="footer">
    <div className="footer-line"></div>
    <div className="footer-line-blue"></div>
    <br />
    <div className="container">
      <div className="row">
        <div className="col-md-6 footer-logo">
          <img src={logo} alt="LM Cloud Consulting Logo" />
          <p>&copy; 2024 LM Cloud Consulting. All rights reserved.</p>
        </div>
        <div className="col-md-6 footer-links">
          <ul>
            <li><Link to="/contact" title="Contact Us">Contact Us</Link></li>
            <li><Link to="/services" title="Services">Services</Link></li>
            <li><Link to="/about" title="About">About</Link></li>
            <li><a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" title="LinkedIn">LinkedIn</a></li>
            <li><Link to="/privacy-policy" title="Privacy">Privacy Policy</Link></li>
            <li><Link to="/faq" title="FAQ">FAQ</Link></li>
            <li><Link to="/booking" title="Booking">Book</Link></li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
