// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Services from './components/Services';
import Contact from './components/Contact';
import Booking from './components/Booking';
import About from './components/About';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import './styles.css';



function App() {
  
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<><Services /><Footer /></>} />
        <Route path="/contact" element={<><Contact /><Footer /></>} />
        <Route path="/booking" element={<><Booking /><Footer /></>} />
        <Route path="/about" element={<><About /><Footer /></>} />
      </Routes>
    </Router>
  );
}

export default App;
